import React, { useState, useEffect } from "react";
import "../screens/MemberPrint.css";
import { PhotoTable } from "./TablePrintFormat";
import { BASE_URL } from "../env";

const GeneratedEmiSchedule = (memberDetails) => {
  const [generatedSchedule, setGeneratedSchedule] = useState([]);
  const [totalInterest, setTotalInterest] = useState(0);

  useEffect(() => {
    handleGenerateSchedule(); // Call the function to generate the schedule
  }, []);

  const handleGenerateSchedule = () => {
    const expectedLoanAmount =
      memberDetails.memberDetails.memberDetails
        .sanctionedLoanAmountBySanctionCommittee;
    const rateOfInterest =
      memberDetails.memberDetails.proposedLoanDetails.rateOfInterest;
    const tenureInMonths =
      memberDetails.memberDetails.proposedLoanDetails.tenureInMonths;
    const emiDateValue = memberDetails.memberDetails.memberDetails
      .emiDateByBranchManager
      ? memberDetails.memberDetails.memberDetails.emiDateByBranchManager
      : memberDetails.memberDetails.proposedLoanDetails.emiDate;

    const monthlyRate = rateOfInterest / 12 / 100;
    const emi =
      (expectedLoanAmount *
        monthlyRate *
        Math.pow(1 + monthlyRate, tenureInMonths)) /
      (Math.pow(1 + monthlyRate, tenureInMonths) - 1);

    let balance = expectedLoanAmount;
    let beginingBalance = balance;
    let sumOfInterest = 0;
    const schedule = [];
    // Extract only the date from transactionDate
    let transactionDate = new Date();

    for (let month = 1; month <= tenureInMonths; month++) {
      const interest = balance * monthlyRate;
      const principal = emi - interest;
      beginingBalance = balance;
      balance -= principal;
      sumOfInterest += interest;

      // Calculate the EMI date for the current month
      const paymentDate = new Date(transactionDate);
      paymentDate.setMonth(transactionDate.getMonth() + month); // Add the current month to the transaction date
      const daysInMonth = new Date(
        paymentDate.getFullYear(),
        paymentDate.getMonth() + 1,
        0
      ).getDate(); // Get the last day of the month

      // Set the emiDate value (adjust to make sure it doesn't exceed the days in the month)
      if (emiDateValue > daysInMonth) {
        paymentDate.setDate(daysInMonth); // If emiDate is more than the last day, set it to the last day of the month
      } else {
        paymentDate.setDate(emiDateValue); // Otherwise, set it to the specified emiDate
      }

      schedule.push({
        month,
        emi,
        principal,
        interest,
        balance: balance > 0 ? balance : 0,
        emiDate: `${String(paymentDate.getDate()).padStart(2, "0")}-${String(
          paymentDate.getMonth() + 1
        ).padStart(2, "0")}-${paymentDate.getFullYear()}`, // Format as DD-MM-YYYY
        beginingBalance,
      });
    }
    setGeneratedSchedule(schedule);
    setTotalInterest(sumOfInterest);
  };

  return (
    <div>
      <div className="section">
        <h1 className="text-center">
          Vetrivikas Capital Trust Repayment Schedule
        </h1>
        <div>
          <table
            className="table table-striped text-center table-bordered"
            style={{ width: "100%" }}
          >
            {/* <thead>
              <tr className="bg-primary">
                <th colSpan={2} rowSpan={2} style={{ width: "30%" }}>
                  Loan Values
                </th>
                <th colSpan={2} rowSpan={2} style={{ width: "40%" }}></th>
                <th colSpan={2} rowSpan={2} style={{ width: "30%" }}>
                  Loan Summary
                </th>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td style={{ width: "15%" }}>Customer Name</td>
                <td style={{ width: "15%" }}>
                  {memberDetails.memberDetails.memberDetails.memberName}
                </td>
                <td style={{ width: "20%" }} rowSpan="3">
                  <img
                    src={`${BASE_URL}/images/${memberDetails.memberDetails.memberDocuments.memberPhoto}`}
                    alt=""
                    style={{ width: "150px", height: "150px" }}
                  />
                </td>
                <td style={{ width: "20%" }} rowSpan="3">
                  <img
                    src={`${BASE_URL}/images/${memberDetails.memberDetails.nomineeDocuments.nomineePhoto}`}
                    alt=""
                    style={{ width: "150px", height: "150px" }}
                  />
                </td>
                <td style={{ width: "15%" }}>Customer Id</td>
                <td style={{ width: "15%" }}>
                  {memberDetails.memberDetails.memberDetails.customerId}
                </td>
              </tr>
              <tr>
                <td>Branch Name</td>
                <td>{memberDetails.memberDetails.branchName}</td>
                <td>Loan Amount</td>
                <td>
                  {memberDetails.memberDetails.memberDetails
                    .sanctionedLoanAmountBySanctionCommittee
                    ? memberDetails.memberDetails.memberDetails
                        .sanctionedLoanAmountBySanctionCommittee
                    : memberDetails.memberDetails.proposedLoanDetails
                        .loanAmount}
                </td>
              </tr>
              <tr>
                <td>Rate of Interest</td>
                <td>
                  {
                    memberDetails.memberDetails.proposedLoanDetails
                      .rateOfInterest
                  }
                  %
                </td>
                <td>Tenure</td>
                <td>
                  {
                    memberDetails.memberDetails.proposedLoanDetails
                      .tenureInMonths
                  }{" "}
                  months
                </td>
              </tr>
              {/* <tr>
                <td>Start Date of Loan</td>
                <td>
                  {`${String(new Date().getDate()).padStart(2, "0")}-${String(
                    new Date().getMonth() + 1
                  ).padStart(2, "0")}-${new Date().getFullYear()}`}
                </td>
                <td></td>
                <td></td>
                <td>Total Cost of Loan</td>
                <td>
                  {Math.round(
                    memberDetails.memberDetails.proposedLoanDetails
                      .tenureInMonths *
                      memberDetails.memberDetails.memberDetails.securityDeposit
                  )}
                </td>
              </tr> */}
            </tbody>
          </table>

          <div className="table-responsive">
            <table className="table table-striped text-center table-bordered">
              <thead className="primaryColor thead_sticky bg-primary">
                <tr>
                  <th>S.No</th>
                  <th>Repayment Date</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Total EMI</th>
                  <th>Outstanding Balance</th>
                </tr>
              </thead>
              <tbody>
                {generatedSchedule.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.emiDate}</td>
                    <td>{Math.round(item.principal)}</td>
                    <td>{Math.round(item.interest)}</td>
                    <td>{Math.round(item.emi)}</td>
                    <td>{Math.round(item.balance)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratedEmiSchedule;
