import React from "react";
import "../screens/MemberPrint.css";
import {
  SanctionLetterTable,
  sanctionLetterFields,
} from "../components/TablePrintFormat";

const MemberSanctionLetter = (memberDetails) => {
  const currentDate = new Date();
  const formattedDate = `${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${currentDate.getFullYear()}`;

  return (
    <div>
      <div>
        <div className="section sanctionHeading d-flex justify-content-center">
          <p className="headingText">
            SCHEDULE / SANCTION LETTER / MOST IMPORTANT DOCUMENT
          </p>
          <p>அட்டவணை / அனுமதி கடிதம் / மிக முக்கியமான ஆவணம்</p>
          <p>
            வெற்றிவிகாஸ் கேபிடல் டிரஸ்ட் நிறுவனத்தில் நான்/நாங்கள் சமர்ப்பித்த
            விண்ணப்ப படிவம் எண்{" "}
            <span style={{ textDecoration: "underline" }}>
              {memberDetails.memberDetails.memberDetails.ApplicationId}
            </span>{" "}
            மற்றும் ------------- தேதியிட்ட விண்ணப்பத்தை நான் / நாங்கள்
            குறிப்பிடுகிறோம். பின்வரும் தகவல் அனைத்தும் என்னால் / எங்களால்
            அதற்கேற்ப பூர்த்தி செய்யப்பட்டுள்ளன.
          </p>
        </div>
        <SanctionLetterTable
          details={memberDetails.memberDetails}
          fields={sanctionLetterFields}
        />
      </div>

      <div className="mt-5 section">
        <p className="sanctionpara">
          * Interest (if any) will be deducted from net disbursement amount /
        </p>
        <p className="sanctionpara">
          * வட்டி (ஏதேனும் இருந்தால்) நிகர விநியோகத் தொகையிலிருந்து
          கழிக்கப்படும்.
        </p>
        <p className="sanctionpara">
          ** Foreclosure Amount (if any) would be deducted from net disbursement
          amount /
        </p>
        <p className="sanctionpara">
          ** நிகர பட்டுவாடா தொகையிலிருந்து முன்கூட்டியே லோனை கட்டி முடிப்பதற்கான
          தொகை (ஏதேனும் இருந்தால்) கழித்துக் கொள்ளப்படும்.
        </p>
        <p className="sanctionpara">
          I / we herby confirm having received a copy of the most important
          ‘Document cum sanction letter and copy of loan agreement governing
          loan from VETRIVIKAS CAPITAL TRUST and further confirm that I / we
          have read and understood the same.
        </p>
        <p className="sanctionpara">
          வெற்றிவிகாஸ் கேபிடல் டிரஸ்ட் நிறுவனத்திடமிருந்து கடனை நிர்வாகிக்கும்
          கட் ஒப்பந்தத்தின் நகல் மற்றும் மிக முக்கியமான ஆவண மற்றும் ஒப்புதல்
          கடிதத்தின் நகலைப் பெற்றிருப்பதை நான் / நாங்கள் இதன் மூலம் உறுதி
          செய்கிறோம். மேலும் நான் / நாங்கள் அதைப்படித்து புரிந்து கொண்டோம்
          என்பதை உறுதிப்படுத்துகிறோம்.
        </p>
        <p className="sanctionpara fw-bold">
          Please read carefully before signing / கையொப்பமிடுவதற்கு முன் கவனமாக
          படிக்கவும்.
        </p>

        <div className="d-flex justify-content-between">
          <div>
            <p> ________________</p>
            <p>Signature Of Applicant</p>
            <p>விண்ணப்பதாரரின் கையொப்பம்</p>
          </div>
          <div>
            <p> ________________</p>
            <p>Signature Of Co-Applicant 1</p>
            <p> இணை விண்ணப்பதாரரின் கையொப்பம் - 1</p>
          </div>
          <div>
            <p> ________________</p>
            <p>Signature Of Co-Applicant 2</p>
            <p>இணை விண்ணப்பதாரரின் கையொப்பம் - 2</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex">
            <p>Date / தேதி </p>
            <p className="mx-2" style={{ textDecoration: "underline" }}>
              {formattedDate}
            </p>
          </div>
          <div className="d-flex mx-2">
            <p>Place / இடம் </p>
            <p className="mx-2" style={{ textDecoration: "underline" }}>
              {memberDetails.memberDetails.branchName}
            </p>
          </div>
        </div>
        <p className="sanctionpara ">
          Note : Please do not issue blank cheque(s) . the cheque(s) should be
          account payee only. The cheque(s) should be in favour of VETRIVIKAS
          CAPITAL TRUST FOR LOAN A/C OF.
        </p>
        <p className="sanctionpara s">
          குறிப்பு : தயவு செய்து வெற்று காசோலைகளை வழங்க வேண்டாம். காசோலை(கள்)
          கணக்கு செலுத்துபவராக மட்டுமே இருக்க வேண்டும். காசோலை(கள்) கடன்
          கணக்குக்கான வெற்றிவிகாஸ் கேபிடல் டிரஸ்ட்- ன் பெயரில் இருக்க வேண்டும்.
        </p>

        <div className=" d-flex justify-content-between printdetails">
          <div>
            <div className="d-flex">
              <p>Authorised Officer Name</p>
              <p className="mx-2"> ________________</p>
            </div>
            <p>அங்கீகரிக்கப்பட்ட அதிகாரியின் பெயர்</p>
          </div>

          <div>
            <div className="d-flex">
              <p>Authorised Officer Employee ID</p>
              <p className="mx-2"> ________________</p>
            </div>
            <p>அங்கீகரிக்கப்பட்ட அதிகாரியின் பணியாளர் எண்</p>
          </div>
        </div>

        <p className="sanctionpara mt-5">
          1. தகவல் தொழில்நுட்பச்சட்டம் 2000 மற்றும் தகவல் தொழில்நுட்பச்சட்டம்
          2000 ஆல் திருத்தப்பட்டபடி பல்வேறு சட்டங்களில் உள்ள மின்னணு
          பதிவுகளுக்கு பொருந்தக்கூடிய விதிகள் ஆகியவற்றின் கீழ் இந்த ஆவணம்
          /விண்ணப்பம் ஒரு மின்னணு பதிவு என்பதை நாங்கள் அறிவோம். இந்த மின்னணு
          பதிவு ஒரு கணினி அமைப்பால் உருவாக்கப்பட்டது மற்றும் எந்த நேரடி
          கையொப்பங்களும் இதற்கு அவசியமில்லை.
        </p>
        <p className="sanctionpara mt-5">
          2. என்னாலோ / எங்களாலோ அல்லது வேறு ஏதேனும் மூன்றாம் தரப்பினராலோ
          வழங்கப்படும் மின்-அஞ்சல் முகவரி / மொபைல் எண் / விபரங்களுக்கு மின்னணு
          தொடர்புகள் அனுப்பப்படுகையில், அவை என்னால் /எங்களால் பெறப்பட்டதாக
          கருதப்படும். வங்கி / அதன் வணிக கூட்டாளிகள் / சேவை வழங்குனர்கள்
          /அங்கீகரிக்கப்பட்ட மூன்றாம் தரப்பினர்களிடமிருந்து பெறப்படும் அத்தகைய
          தொடர்புகளுக்கு வங்கி அல்லது வங்கிகள் , வணிக கூட்டாளிகள் / சேவை
          வழங்குனர்கள் / அங்கீகரிக்கப்பட்ட மூன்றாம் தரப்பினர்களை / நான் நாங்கள்
          பொறுப்பாக்க மாட்டோம். மேலும் அத்தகைய ஏதேனும் தொடர்புகளை, நேஷனல்டு நாட்
          காலர் பதிவில் ஸ்பேம் , கோரப்படாத தொடர்பு அல்லது எனது / எங்களின்
          பதிவுகளின் புறக்கணிப்பாக கருதமாட்டோம்.
        </p>
        <p className="sanctionpara mt-5">
          3. "நான் ஏற்றுக் கொள்கிறேன்"அல்லது "சம்மதிக்கிறேன்" அல்லது ஆன் லைன்
          சிஸ்டம் / வலை தளம் / போர்ட்டல் / வணிகர் / அவரின் ஏஜெண்ட் / சேவை
          வழங்குனர் / கூட்டாளிகளின் பிளாட் / பாரத்தில் காணப்படும் அதேபோன்ற
          ஏதேனும் ஐகான் / டேப் / மாற்றுத் தேர்வில் டிக்கிங் / க்ளிக்கிங் /
          செக்கிங் செய்வது மூலம் ஆன்லைன் விண்ணப்ப படிவம். முக்கிய உண்மை
          அறிக்கையில் (மொத்தமாக ‘ கடன் ஆவணங்கள் ” ) அடங்கியுள்ள உள்ளடக்கங்கள் /
          விதிகள் மற்றும் நிபந்தனைகள் அனைத்தையும் நான் /நாங்கள் படித்தோம். சரி
          பார்த்தோம். மாற்றாமல் சம்மதித்தோம் மற்றும் ஏற்றுக் கொண்டோம் என்பதை
          நான் /நாங்கள் இங்கே வெளிப்படையாக ஏற்றுக் கொள்கிறோம் மற்றும்
          உறுதிப்படுத்துகிறோம் மற்றும் நிறுவனத்துடன் பதிவு செய்து கொண்ட எனது /
          எங்களின் மொபைல் எண்ணில் கொடுக்கப்படும் ஒரு தடவை பாஸ்வேர்டை (OTP) பதிவு
          செய்வோம் மற்றும் கடன் ஆவணங்களில் எனது / எங்களின் கையொப்பம் அல்லது
          நேரடி கையொப்பத்துக்கு அவசியம் இல்லை ” என்பதை புரிந்து கொண்டோம்.
          விண்ணப்பத்தை நிறுவனம் ஒரு முறை ஏற்றுக் கொண்டதும் விண்ணப்பம்
          செயல்படுத்தப்படும் என்பதை நான் / நாங்கள் ஒப்புக் கொள்கிறோம். நிறுவனம்
          தனது அதிகாரி மூலம் ஒரு மின்னஞ்சல் / கடிதம் SMS -ஐ எனக்கு / எங்களுக்கு
          அனுப்புவதுடன்அதனுடன் இந்த ஆவணத்தின் பிரதியை இணைப்பது மூலம் நிறுவனம்
          ஆன்லைனில் இந்த ஆவணத்தை ஏற்றுக் கொண்டு கையொப்பமிட்டதாக கருதப்படும்.
        </p>
        <p className="sanctionpara mt-5">
          VETRIVIKAS CAPITAL TRUST Customer Care Number / வெற்றி விகாஸ் கேபிடல்
          டிரஸ்ட் வாடிக்கையாளர் சேவை எண்: 0452 – 4909438.
        </p>
        <div className="mt-5">
          Email / மின்னஞ்சல் : office@vetrivikascapital.com
        </div>
      </div>
    </div>
  );
};

export default MemberSanctionLetter;
