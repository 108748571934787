import React, { useState, useEffect } from "react";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { useNavigate } from "react-router-dom";
import { FaUserLock } from "react-icons/fa";
import { BiSolidUserDetail } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa6";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { MdDangerous } from "react-icons/md";
import CountUp from "react-countup";
import axios from "axios";
import { BASE_URL } from "../env";
import "./Dashboard.css";

const Dashboard = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [loginCredentialCount, setLoginCredentialCount] = useState(0);
  const [pendingMemberCount, setPendingMemberCount] = useState(0);
  const [approvedMemberCount, setApprovedMemberCount] = useState(0);
  const [rejectedMemberCount, setRejectedMemberCount] = useState(0);
  const [totalDisbursement, setTotalDisbursement] = useState(0);
  const [totalBorrowerCount, setTotalBorrowerCount] = useState(0);
  const [totalSecurityDeposit, setTotalSecurityDeposit] = useState(0);
  const [totalProcessingCharge, setTotalProcessingCharge] = useState(0);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const { adminUser } = useAdminAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setIsSuperAdmin(adminUser && adminUser.userType === "superadmin");
  }, [adminUser]);

  const handleLoginCredentialsClick = () => {
    navigate("/logincredential");
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/dashboardcount`);
        // console.log("loginResponse: " + JSON.stringify(loginResponse.data));
        setLoginCredentialCount(response.data.userCount);
        setPendingMemberCount(response.data.pendingMemberCount);
        setApprovedMemberCount(response.data.approvedMemberCount);
        setRejectedMemberCount(response.data.rejectedMemberCount);
        setTotalDisbursement(response.data.disbursedLoanAmount);
        setTotalBorrowerCount(response.data.disbursedLoanCount);
        setTotalSecurityDeposit(response.data.securityDepositAmount);
        setTotalProcessingCharge(response.data.processingChargeAmount);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    if (isSuperAdmin) {
      fetchCounts();
    }
  }, [isSuperAdmin]);

  return (
    <div className="dashboard-over">
      <div className="d-flex ">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid "
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <div className="row ">
            <DashboardHeader OpenSidebar={OpenSidebar} />
            {isSuperAdmin && (
              <div
                className="col-md-4 col-xl-3"
                style={{ position: "relative" }}
              >
                <div
                  className="dashboardcard bg-c-blue dashboard-card container-crd"
                  onClick={handleLoginCredentialsClick}
                >
                  {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingUpIcon" style={{ position: 'absolute', opacity: 0.4, width: 110, right: '30%', top: 25 }}><path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"></path></svg> */}
                  <div className="dashboardCard-image">
                    {" "}
                    <FaUserLock
                      className="dashboardCard-icon content-crd"
                      style={{ color: "white" }}
                    />
                  </div>
                  <div
                    className="counter text-center content-crd"
                    style={{ fontSize: "30px" }}
                  >
                    <CountUp
                      start={0}
                      end={loginCredentialCount}
                      duration={2.5}
                    />
                  </div>
                  <div className="dashboardCard-block content-crd">
                    <p style={{ fontWeight: "500" }} className="m-b-20">
                      Login Credentials
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-4 col-xl-3" style={{ position: "relative" }}>
              <div
                className="dashboardcard bg-c-green dashboard-card container-crd"
                onClick={() =>
                  navigate("/memberdetails", {
                    state: {
                      manager: "",
                      filterStatus: "",
                      isActive: "Member Details",
                    },
                  })
                }
              >
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingUpIcon" style={{ position: 'absolute', opacity: 0.1, width: 110, left: 7, top: 10 }}><path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"></path></svg> */}
                <div className="dashboardCard-image content-crd">
                  {" "}
                  <BiSolidUserDetail
                    className="dashboardCard-icon"
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  className="counter text-center content-crd"
                  style={{ fontSize: "30px" }}
                >
                  <CountUp start={0} end={pendingMemberCount} duration={2.5} />
                </div>
                <div className="dashboardCard-block content-crd">
                  <p style={{ fontWeight: "500" }} className="m-b-20">
                    Member Details
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ position: "relative" }}>
              <div
                className="dashboardcard bg-c-yellow dashboard-card container-crd"
                onClick={() =>
                  navigate("/memberdetails", {
                    state: {
                      filterStatus: "approved",
                      isActive: "Approved",
                    },
                  })
                }
              >
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingUpIcon" style={{ position: 'absolute', opacity: 0.1, width: 110, left: 7, top: 10 }}><path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"></path></svg> */}
                <div className="dashboardCard-image content-crd">
                  <FaUserCheck
                    className="dashboardCard-icon"
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  className="counter text-center content-crd"
                  style={{ fontSize: "30px" }}
                >
                  <CountUp start={0} end={approvedMemberCount} duration={2.5} />
                </div>
                <div className="dashboardCard-block content-crd">
                  <p style={{ fontWeight: "500" }} className="m-b-20">
                    Approved Member Details
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ position: "relative" }}>
              <div
                className="dashboardcard bg-c-pink dashboard-card container-crd"
                onClick={() =>
                  navigate("/memberdetails", {
                    state: {
                      filterStatus: "rejected",
                      isActive: "Rejected",
                    },
                  })
                }
              >
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingDownIcon" style={{ position: 'absolute', opacity: 0.1, width: 110, left: 7, top: 25 }}><path d="m16 18 2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"></path></svg> */}
                <div className="dashboardCard-image content-crd">
                  {" "}
                  <MdDangerous
                    className="dashboardCard-icon"
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  className="counter text-center content-crd"
                  style={{ fontSize: "30px" }}
                >
                  <CountUp start={0} end={rejectedMemberCount} duration={2.5} />
                </div>
                <div className="dashboardCard-block content-crd">
                  <p style={{ fontWeight: "500" }} className="m-b-20">
                    Rejected Member Details{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ position: "relative" }}>
              <div className="dashboardcard bg-c-pink dashboard-card container-crd">
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingDownIcon" style={{ position: 'absolute', opacity: 0.1, width: 110, left: 7, top: 25 }}><path d="m16 18 2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"></path></svg> */}
                <div className="dashboardCard-image content-crd">
                  {" "}
                  <MdDangerous
                    className="dashboardCard-icon"
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  className="counter text-center content-crd"
                  style={{ fontSize: "30px" }}
                >
                  <CountUp start={0} end={totalDisbursement} duration={2.5} />
                </div>
                <div className="dashboardCard-block content-crd">
                  <p style={{ fontWeight: "500" }} className="m-b-20">
                    Total Disbursement
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ position: "relative" }}>
              <div className="dashboardcard bg-c-pink dashboard-card container-crd">
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingDownIcon" style={{ position: 'absolute', opacity: 0.1, width: 110, left: 7, top: 25 }}><path d="m16 18 2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"></path></svg> */}
                <div className="dashboardCard-image content-crd">
                  {" "}
                  <MdDangerous
                    className="dashboardCard-icon"
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  className="counter text-center content-crd"
                  style={{ fontSize: "30px" }}
                >
                  <CountUp start={0} end={totalBorrowerCount} duration={2.5} />
                </div>
                <div className="dashboardCard-block content-crd">
                  <p style={{ fontWeight: "500" }} className="m-b-20">
                    Total Borrowers
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ position: "relative" }}>
              <div className="dashboardcard bg-c-pink dashboard-card container-crd">
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingDownIcon" style={{ position: 'absolute', opacity: 0.1, width: 110, left: 7, top: 25 }}><path d="m16 18 2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"></path></svg> */}
                <div className="dashboardCard-image content-crd">
                  {" "}
                  <MdDangerous
                    className="dashboardCard-icon"
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  className="counter text-center content-crd"
                  style={{ fontSize: "30px" }}
                >
                  <CountUp
                    start={0}
                    end={totalSecurityDeposit}
                    duration={2.5}
                  />
                </div>
                <div className="dashboardCard-block content-crd">
                  <p style={{ fontWeight: "500" }} className="m-b-20">
                    Security Deposit
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ position: "relative" }}>
              <div className="dashboardcard bg-c-pink dashboard-card container-crd">
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TrendingDownIcon" style={{ position: 'absolute', opacity: 0.1, width: 110, left: 7, top: 25 }}><path d="m16 18 2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"></path></svg> */}
                <div className="dashboardCard-image content-crd">
                  {" "}
                  <MdDangerous
                    className="dashboardCard-icon"
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  className="counter text-center content-crd"
                  style={{ fontSize: "30px" }}
                >
                  <CountUp
                    start={0}
                    end={totalProcessingCharge}
                    duration={2.5}
                  />
                </div>
                <div className="dashboardCard-block content-crd">
                  <p style={{ fontWeight: "500" }} className="m-b-20">
                    Processing Charge
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
