// Utility function to calculate the first and last EMI dates based on disbursement date and tenure
export const getFirstAndLastEmiDates = (
  disbursementDate,
  tenureInMonths,
  emiDay
) => {
  const disbursement = new Date(disbursementDate);

  // First EMI Date: based on the EMI day in the disbursement month
  const firstEmiDate = new Date(
    disbursement.getFullYear(),
    disbursement.getMonth(),
    emiDay
  );
  // if (firstEmiDate < disbursement) {
  //   // If the EMI day has already passed in the disbursement month, move to the next month
  //   firstEmiDate.setMonth(firstEmiDate.getMonth() + 1);
  // }
  firstEmiDate.setMonth(firstEmiDate.getMonth() + 1);

  // Last EMI Date: based on the tenure and EMI day
  const lastEmiDate = new Date(
    disbursement.getFullYear(),
    disbursement.getMonth() + tenureInMonths,
    emiDay
  );

  return { firstEmiDate, lastEmiDate };
};

// Utility function to get EMI dates within the range of first EMI and last EMI
export const getEmiDatesInRange = (
  emiDay,
  fromDate,
  toDate,
  firstEmiDate,
  lastEmiDate
) => {
  const emiDates = [];
  const from = new Date(fromDate);
  const to = new Date(toDate);

  let currentDate = new Date(from);

  // console.log("fromDate: " + from);
  // console.log("toDate: " + to);
  // console.log("from as currentDate: " + currentDate);
  // console.log("emiDay: " + emiDay);

  while (currentDate <= to) {
    const emiDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      emiDay
    );
    if (emiDate >= firstEmiDate && emiDate <= lastEmiDate && emiDate <= to) {
      emiDates.push(emiDate); // Pushing the `emiDate` as it is
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  // console.log("emiDates: " + JSON.stringify(emiDates));
  return emiDates;
};

// Calculate EMI amount based on the provided formula
export const calculateEmiAmount = (
  sanctionedAmount,
  rateOfInterest,
  tenureInMonths
) => {
  const monthlyRate = rateOfInterest / 12 / 100;
  return (
    (sanctionedAmount *
      monthlyRate *
      Math.pow(1 + monthlyRate, tenureInMonths)) /
    (Math.pow(1 + monthlyRate, tenureInMonths) - 1)
  ).toFixed(2); // Fixed to 2 decimal points
};

// Generate rows with EMI dates within the range of first EMI date and last EMI date
export const generateRowsWithEmiDates = (data, fromDate, toDate) => {
  // Check if disbursement date exists and calculate first/last EMI date
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Calculate EMI amount
    const emiAmount = calculateEmiAmount(
      data.sanctionedLoanAmountBySanctionCommittee,
      data.proposedLoanDetails.rateOfInterest,
      data.proposedLoanDetails.tenureInMonths
    );

    // Return multiple rows for each EMI date within the range
    const result = emiDates.map((emiDate) => ({
      ...data,
      formattedEmiDateByBranchManager: emiDate,
      emiAmount, // Add the calculated EMI amount to each row
    }));

    // Log the result before returning
    // console.log("EMI Data:", result);

    return result;
  }
  return []; // No EMI dates to display if not disbursed
};

export const generateTotalPendingAmountForMember = (data, fromDate, toDate) => {
  // Check if disbursement date exists and calculate first/last EMI date
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within the range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Calculate EMI amount
    const emiAmount = calculateEmiAmount(
      data.sanctionedLoanAmountBySanctionCommittee,
      data.proposedLoanDetails.rateOfInterest,
      data.proposedLoanDetails.tenureInMonths
    );

    // Initialize total pending amount and track the first fully pending EMI date
    let totalPendingAmount = 0;
    let firstPendingEmiDate = null;

    // Loop through all EMI dates and calculate total pending amount
    emiDates.forEach((emiDate) => {
      const formattedEmiDate = `${emiDate.getFullYear()}-${String(
        emiDate.getMonth() + 1
      ).padStart(2, "0")}-${String(emiDate.getDate()).padStart(2, "0")}`;

      // Get receipts for this emiDate
      const receiptsForEmiDate = data.receiptsDetails.filter(
        (receipt) => receipt.emiDate === formattedEmiDate
      );

      // Check if any receipt for this emiDate is marked as "paid"
      const isFullyPending = receiptsForEmiDate.every(
        (receipt) => receipt.status === "pending"
      );

      // Calculate the total received amount for this emiDate
      const totalReceivedAmount = receiptsForEmiDate.reduce(
        (sum, receipt) => sum + receipt.receivedAmount,
        0
      );

      // Calculate pending amount for this EMI date
      let pendingAmount = 0;
      if (receiptsForEmiDate.length === 0) {
        // Full EMI is pending if no receipts are found
        pendingAmount = emiAmount;
      } else if (totalReceivedAmount < emiAmount) {
        // Partial payment, remaining amount is pending
        pendingAmount = emiAmount - totalReceivedAmount;
      }

      // If this EMI date is fully pending and no previous pending EMI was found, set this as the first pending EMI date
      if (pendingAmount > 0 && isFullyPending && !firstPendingEmiDate) {
        firstPendingEmiDate = emiDate;
      }

      // Accumulate pending amount for this date to totalPendingAmount
      totalPendingAmount += Math.round(pendingAmount); // Use rounding to avoid floating-point issues
    });

    // Calculate overdue days if there is a first pending EMI date
    let overdueDays = 0;
    if (firstPendingEmiDate) {
      const currentDate = new Date();
      const timeDiff = currentDate - firstPendingEmiDate;
      overdueDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }

    // If totalPendingAmount is greater than 0, return a single row with overdueDays
    if (totalPendingAmount > 0) {
      return {
        ...data,
        totalPendingAmount, // The total pending amount across all EMI dates
        status: "Pending",
        overdueDays, // Add overdue days from the first fully pending EMI date
      };
    }
  }

  return null; // No pending amount or not disbursed
};

export const generateOutstandingAmountsForParMember = (
  data,
  fromDate,
  toDate
) => {
  // Check if the loan has been disbursed and has an updated status
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within the range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Calculate EMI amount
    const emiAmount = Math.round(
      calculateEmiAmount(
        data.sanctionedLoanAmountBySanctionCommittee,
        data.proposedLoanDetails.rateOfInterest,
        data.proposedLoanDetails.tenureInMonths
      )
    );

    // Initialize total outstanding amounts
    let totalOutstandingPrincipal = Math.round(
      data.sanctionedLoanAmountBySanctionCommittee
    );
    let totalOutstandingInterest = 0;
    let firstMissedEmiDate = null; // Track the first missed EMI date
    let hasMissedEmi = false; // Flag to track if any EMI has been missed

    // Loop through all EMI dates and calculate outstanding principal and interest
    emiDates.forEach((emiDate) => {
      const formattedEmiDate = `${emiDate.getFullYear()}-${String(
        emiDate.getMonth() + 1
      ).padStart(2, "0")}-${String(emiDate.getDate()).padStart(2, "0")}`;

      // Get receipts for this emiDate
      const receiptsForEmiDate = data.receiptsDetails.filter(
        (receipt) => receipt.emiDate === formattedEmiDate
      );

      // Declare totalReceivedAmount as let to allow modification
      let totalReceivedAmount = Math.round(
        receiptsForEmiDate.reduce(
          (sum, receipt) => sum + receipt.receivedAmount,
          0
        )
      );

      // Calculate interest component for this EMI date
      const interestComponent = Math.round(
        (totalOutstandingPrincipal * data.proposedLoanDetails.rateOfInterest) /
          12 /
          100
      ); // Monthly interest on the outstanding principal

      // Check if the EMI payment is skipped
      if (totalReceivedAmount < emiAmount) {
        hasMissedEmi = true; // Set the flag to true if any EMI is missed

        // Set the first missed EMI date if it's not already set
        if (!firstMissedEmiDate) {
          firstMissedEmiDate = emiDate;
        }
      }

      // Calculate outstanding amounts
      if (totalReceivedAmount >= interestComponent) {
        // Deduct interest first
        totalOutstandingInterest += interestComponent; // Add this month's interest to total outstanding interest
        totalReceivedAmount -= interestComponent; // Remaining amount after paying interest

        // Deduct any remaining amount from the principal
        totalOutstandingPrincipal -= Math.round(totalReceivedAmount); // Round remaining principal
      } else {
        // If the received amount doesn't cover the interest, just add to the outstanding interest
        totalOutstandingInterest += Math.round(
          interestComponent - totalReceivedAmount
        ); // Remaining interest due
      }
    });

    // Calculate overdue days if there's a missed EMI date
    let overdueDays = 0;
    if (firstMissedEmiDate) {
      const currentDate = new Date();
      const timeDiff = currentDate - firstMissedEmiDate;
      overdueDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }

    // Return only if any EMI has been missed
    if (hasMissedEmi) {
      return {
        ...data,
        totalOutstandingPrincipal: Math.round(totalOutstandingPrincipal),
        totalOutstandingInterest: Math.round(totalOutstandingInterest),
        overdueDays, // Include overdue days
      };
    }
  }

  return null; // No outstanding amount or not disbursed
};

export const generateOutstandingAmountsForAllMember = (
  data,
  fromDate,
  toDate
) => {
  // Check if the loan has been disbursed and has an updated status
  if (
    data.branchManagerStatus === "disbursed" &&
    data.branchManagerStatusUpdatedAt
  ) {
    const { firstEmiDate, lastEmiDate } = getFirstAndLastEmiDates(
      data.branchManagerStatusUpdatedAt,
      data.proposedLoanDetails.tenureInMonths,
      data.emiDateByBranchManager
    );

    // Get EMI dates within the range
    const emiDates = getEmiDatesInRange(
      data.emiDateByBranchManager,
      fromDate,
      toDate,
      firstEmiDate,
      lastEmiDate
    );

    // Initialize total outstanding amounts
    let totalOutstandingPrincipal = Math.round(
      data.sanctionedLoanAmountBySanctionCommittee
    );
    let totalOutstandingInterest = 0;

    // Loop through all EMI dates and calculate outstanding principal and interest
    emiDates.forEach((emiDate) => {
      const formattedEmiDate = `${emiDate.getFullYear()}-${String(
        emiDate.getMonth() + 1
      ).padStart(2, "0")}-${String(emiDate.getDate()).padStart(2, "0")}`;

      // Get receipts for this emiDate
      const receiptsForEmiDate = data.receiptsDetails.filter(
        (receipt) => receipt.emiDate === formattedEmiDate
      );

      // Calculate total received amount for this EMI date
      let totalReceivedAmount = Math.round(
        receiptsForEmiDate.reduce(
          (sum, receipt) => sum + receipt.receivedAmount,
          0
        )
      );

      // Calculate interest component for this EMI date
      const interestComponent = Math.round(
        (totalOutstandingPrincipal * data.proposedLoanDetails.rateOfInterest) /
          12 /
          100
      ); // Monthly interest on the outstanding principal

      // Calculate outstanding amounts
      if (totalReceivedAmount >= interestComponent) {
        // Deduct interest first
        totalOutstandingInterest += interestComponent; // Add this month's interest to total outstanding interest
        totalReceivedAmount -= interestComponent; // Remaining amount after paying interest

        // Deduct any remaining amount from the principal
        totalOutstandingPrincipal -= Math.round(totalReceivedAmount); // Round remaining principal
      } else {
        // If the received amount doesn't cover the interest, just add to the outstanding interest
        totalOutstandingInterest += Math.round(
          interestComponent - totalReceivedAmount
        ); // Remaining interest due
      }
    });

    // Return outstanding amounts without checking for missed EMIs or overdue days
    return {
      ...data,
      totalOutstandingPrincipal: Math.round(totalOutstandingPrincipal),
      totalOutstandingInterest: Math.round(totalOutstandingInterest),
    };
  }

  return null; // No outstanding amount or not disbursed
};
