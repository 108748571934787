import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Select from "react-select";

const RetriggerModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  comment,
  setComment,
  retriggerToRole,
  setRetriggerToRole,
  roleId,
  item,
}) => {
  const [validationError, setValidationError] = useState("");
  const options = [];

  if (
    [
      item.branchManagerStatus === "pending" && "Branch Manager",
      "Credit Officer",
      item.misStatus === "pending" && "MIS",
      "Credit Manager",
      "Sanction Committee",
      item.sanctionCommitteeStatus === "approved" && "Accounts Manager",
    ]
      .filter(Boolean)
      .includes(roleId) // Filter out any false or undefined values
  ) {
    options.push({
      label: "Customer Relationship Officer",
      value: "Customer Relationship Officer",
    });
  }

  if (
    ["Credit Officer", "MIS", "Credit Manager", "Sanction Committee"]
      .filter(Boolean)
      .includes(roleId)
  ) {
    options.push({ label: "Branch Manager", value: "Branch Manager" });
  }

  if (
    [
      item.misStatus === "pending" && "MIS",
      "Credit Manager",
      "Sanction Committee",
    ]
      .filter(Boolean)
      .includes(roleId)
  ) {
    options.push({ label: "Credit Officer", value: "Credit Officer" });
  }

  if (
    [
      "Credit Manager",
      "Sanction Committee",
      item.misStatus === "submitted" &&
        item.branchManagerStatus === "disbursed" &&
        "Branch Manager",
    ].includes(roleId)
  ) {
    options.push({ label: "MIS", value: "MIS" });
  }

  if (["Sanction Committee"].includes(roleId)) {
    options.push({ label: "Credit Manager", value: "Credit Manager" });
  }

  if (
    [
      item.branchManagerStatus === "submitted" &&
        item.sanctionCommitteeStatus === "approved" &&
        "Branch Manager",
    ].includes(roleId)
  ) {
    options.push({ label: "Sanction Committee", value: "Sanction Committee" });
  }

  const handleClick = () => {
    if (!comment || !retriggerToRole) {
      setValidationError("Please fill all fields");
    } else {
      setValidationError("");
      handleConfirm();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <Form.Group>
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter Comment"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            required
          />
        </Form.Group>
        Retrigger To
        <Select
          value={options.find((option) => option.value === retriggerToRole)}
          onChange={(selectedOption) =>
            setRetriggerToRole(selectedOption.value)
          }
          options={options}
          placeholder="-- Select Role --"
          isClearable
        />
        <p>{validationError}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Retrigger
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RetriggerModal;
