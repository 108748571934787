import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from "../env";
import axios from "axios";
import "../screens/AdminDashboard.css";
import { useNavigate } from "react-router-dom";
import "../screens/MemberPrint.css";
import Zoom from "react-medium-image-zoom";

const MemberDetailsModal = ({ show, handleClose, memberId }) => {
  const navigate = useNavigate();
  const [memberDetails, setMemberDetails] = useState(null);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (memberId) {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/loan/formdetails/${memberId}`
          );
          console.log(response.data, "memberdetails modal");
          setMemberDetails(response.data);
        } catch (error) {
          console.error("Error fetching member details:", error);
        }
      }
    };

    fetchMemberDetails();
  }, [memberId]);

  const resetPhotoStates = () => {
    handleClose();
  };

  const handlePrintClick = () => {
    navigate("/memberdetail/print", {
      state: {
        memberDetails,
      },
    });
  };
  const sourcingFields = [
    { key: "branchName", label: "Branch Name" },
    { key: "managerName", label: "Manager Name" },
  ];
  const memberFields = [
    { key: "ApplicationId", label: "Application Id" },
    { key: "title", label: "Title" },
    { key: "memberName", label: "Name" },
    { key: "gender", label: "Gender" },
    { key: "dateOfBirth", label: "Date Of Birth" },
    { key: "age", label: "Age" },
    { key: "phoneNumber", label: "Phone No" },
    { key: "aadharNo", label: "Aadhar No" },
    { key: "panNo", label: "Pan No" },
    { key: "anotherIdentity", label: "Another Identity" },
    { key: "anotherIdentityId", label: "Another Identity Id" },
    { key: "ebNumber", label: "EB Number" },
    { key: "familyCardNo", label: "Family Card No" },
    { key: "rationShopNo", label: "Ration Shop No" },
  ];
  const fieldManagerMessage = [
    { key: "fieldManagerMessage", label: "Comment" },
  ];
  const branchManagerMessage = [
    { key: "branchManagerMessage", label: "Comment" },
  ];
  const creditOfficerMessage = [
    { key: "creditOfficerMessage", label: "Comment" },
  ];
  const comments = [
    { key: "creditManagerMessage", label: "Credit Manager Comment" },
    { key: "misMessage", label: "MIS Comment" },
    { key: "sanctionCommitteeMessage", label: "Sanction Committee Comment" },
    { key: "accountManagerMessage", label: "Account Manager Comment" },
  ];

  const businessFields = [
    { key: "sourceOfIncome", label: "Source Of Income" },
    { key: "shopName", label: "Shop Name" },
    { key: "businessType", label: "Business Type" },
    { key: "natureOfBusiness", label: "Nature Of Business" },
    { key: "shopAddress", label: "Shop Address" },
    { key: "shopLocation", label: "Shop Location" },
    { key: "overallBusinessExperience", label: "over All Business Experience" },
    { key: "stabilityInBusinessInYears", label: "Stability In Years" },
    { key: "stabilityInBusinessInMonths", label: "Stability In Months" },
    {
      key: "yearsOfStabilityInCurrentAddress",
      label: " Stability In Current Address (yrs)",
    },
    { key: "monthlyBusinessIncome", label: "Monthly Business Income" },
    { key: "gstNumber", label: "GST Number" },
    { key: "udhayamNumber", label: "Udhayam Number" },
    { key: "district", label: "District" },
    { key: "state", label: "State" },
    { key: "nationality", label: "Nationality" },
  ];

  const residenceFields = [
    { key: "residenceType", label: "Residence Type" },
    { key: "stayingStabilityInYears", label: "Staying Stability (Years)" },
    { key: "stayingStabilityInMonths", label: "Staying Stability (Months)" },
    { key: "currentDoorNo", label: " Door No" },
    { key: "currentStreetNo", label: " Street No" },
    { key: "currentPanchayat", label: " Panchayat" },
    { key: "currentTaluk", label: " Taluk" },
    { key: "currentDistrict", label: " District" },
    { key: "currentPincode", label: " Pincode" },
    { key: "state", label: "State" },
    { key: "nationality", label: "Nationality" },
  ];

  const permanentResidenceFields = [
    { key: "permanentDoorNo", label: "Door No" },
    { key: "permanentStreetNo", label: "Street No" },
    { key: "permanentPanchayat", label: " Panchayat" },
    { key: "permanentTaluk", label: "Taluk" },
    { key: "permanentDistrict", label: "District" },
    { key: "permanentPincode", label: "Pincode" },
  ];

  const CoApplicantPersonalFields = [
    { key: "title", label: "Title" },
    { key: "coApplicantName", label: "Name" },
    { key: "gender", label: "Gender" },
    { key: "relation", label: "Relation" },
    { key: "dateOfBirth", label: "Date Of Birth" },
    { key: "age", label: "Age" },
    { key: "phoneNumber", label: "Phone No" },
    { key: "aadharNo", label: "Aadhar No" },
    { key: "panNo", label: "Pan No" },
    { key: "anotherIdentity", label: "Another Identity" },
    { key: "anotherIdentityId", label: "Another Identity Id" },
    { key: "ebNumber", label: "EB Number" },
    { key: "familyCardNo", label: "Family Card No" },
    { key: "rationShopNo", label: "Ration Shop No" },
  ];

  const coApplicantBusinessFields = [
    { key: "name", label: "Name" },
    { key: "sourceOfIncome", label: "Source Of Income" },
    { key: "designation", label: "Designation" },
    {
      key: "experienceInCurrentCompanyInYears",
      label: "Experience In Current Company (Years)",
    },
    {
      key: "experienceInCurrentCompanyInMonths",
      label: "Experience In Current Company (Months)",
    },
    { key: "companyName", label: "Company Name" },
    { key: "companyAddress", label: "Company Address" },
    { key: "overallExperienceInYears", label: "Overall Experience (Years)" },
    { key: "overallExperienceInMonths", label: "Overall Experience (Months)" },
    { key: "monthlySalary", label: "Monthly Salary" },
    { key: "salaryDate", label: "Salary Date" },
    { key: "shopName", label: "Shop Name" },
    { key: "shopAddress", label: "Shop Address" },
    { key: "shopLocation", label: "Shop Location" },
    { key: "businessType", label: "Business Type" },
    { key: "natureOfBusiness", label: "Nature Of Business" },
    {
      key: "stabilityInBusinessInYears",
      label: "Stability In Business (Years)",
    },
    {
      key: "stabilityInBusinessInMonths",
      label: "Stability In Business (Months)",
    },
    {
      key: "yearsOfStabilityInCurrentAddress",
      label: "Years Of Stability In Current Address",
    },
    { key: "monthlyBusinessIncome", label: "Monthly Business Income" },
    { key: "gstNumber", label: "GST Number" },
    { key: "isUdhayamRegistered", label: "Udhayam Registered" },
    { key: "udhayamNumber", label: "Udhayam Number" },
    { key: "district", label: "District" },
    { key: "state", label: "State" },
    { key: "nationality", label: "Nationality" },
    { key: "overallBusinessExperience", label: "Overall Business Experience" },
  ];

  const coApplicantResidenceFields = [
    { key: "residenceType", label: "Residence Type" },
    { key: "stayingStabilityInYears", label: "Staying Stability (Years)" },
    { key: "stayingStabilityInMonths", label: "Staying Stability (Months)" },
    { key: "currentDoorNo", label: " Door No" },
    { key: "currentStreetNo", label: " Street No" },
    { key: "currentPanchayat", label: " Panchayat" },
    { key: "currentTaluk", label: " Taluk" },
    { key: "currentDistrict", label: " District" },
    { key: "currentPincode", label: " Pincode" },
    { key: "state", label: "State" },
    { key: "nationality", label: "Nationality" },
  ];

  const coApplicantPermanentResidenceFields = [
    { key: "permanentDoorNo", label: "Door No" },
    { key: "permanentStreetNo", label: "Street No" },
    { key: "permanentPanchayat", label: " Panchayat" },
    { key: "permanentTaluk", label: "Taluk" },
    { key: "permanentDistrict", label: "District" },
    { key: "permanentPincode", label: "Pincode" },
  ];

  const loanDetailsFields = [
    { key: "totalloanAmount", label: "Total Loan Amount" },
    { key: "currentNoOfLoans", label: "Current No. of Loans" },
    { key: "loanCompanyNames", label: "Loan Company Names" },
    { key: "totalmonthlyEmi", label: "Total Monthly EMI" },
  ];

  const proposedLoanDetailsFields = [
    { key: "emiDate", label: "EMI Date" },
    { key: "loanAmount", label: "Loan Amount" },
    { key: "monthlyEmi", label: "Monthly EMI" },
    { key: "rateOfInterest", label: "Rate of Interest (%)" },
    { key: "tenureInMonths", label: "Tenure (Months)" },
    { key: "houseHoldExpenses", label: "Household Expenses" },
    { key: "otherExpenses", label: "Other Expenses" },
  ];

  const bankDetailsFields = [
    { key: "bankName", label: "Bank Name" },
    { key: "customerName", label: "Customer Name" },
    { key: "accountNumber", label: "Account Number" },
    { key: "ifscCode", label: "IFSC Code" },
    { key: "bankBranch", label: "Bank Branch" },
  ];

  const creditOfficerGeneratedData = [
    { key: "applicantIncome", label: "Application Income" },
    { key: "coApplicantIncome", label: "Co-Applicant Income" },
    { key: "otherIncome", label: "Other Income" },
    { key: "businessExpenses", label: "Business Expenses" },
    { key: "householdExpenses", label: "Household Expenses" },
    { key: "otherExpenses", label: "Other Expenses" },
    { key: "existingEmi", label: "Existing Emi" },
    { key: "balance", label: "Balance" },
    {
      key: "sanctionedLoanAmountByCreditOfficer",
      label: "Sanctioned Loan Amount by Credit Officer",
    },
  ];

  const renderImage = (imageName) => {
    return imageName ? (
      <Zoom>
        <img
          src={`${BASE_URL}/images/${imageName}`}
          alt=""
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",

            objectFit: "contain",
          }}
        />
      </Zoom>
    ) : (
      <p></p>
    );
  };

  const DetailTable = ({ details, fields }) => {
    const rows = [];

    for (let i = 0; i < fields.length; i += 3) {
      rows.push(fields.slice(i, i + 3));
    }

    return (
      <table className="member-details-table ">
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map(({ key, label }) => {
                let value = details[key];
                if (value === null || value === undefined) {
                  value = "";
                }
                return (
                  <td key={key}>
                    <div style={{ display: "flex" }}>
                      <div className="label-container">
                        <p>{label}</p>
                      </div>
                      <div className="value-container">
                        <p>{value}</p>
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const PhotoTable = ({ details, fields }) => {
    return (
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table className="photo-table">
          <thead>
            <tr>
              {fields.map(({ key }) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fields.map(({ key }) => (
                <>
                  <td key={key}>
                    <div className="value-container">
                      {renderImage(details[key])}
                    </div>
                  </td>
                </>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const VerificationPhotos = ({ photos }) => {
    const groupedPhotos = [];

    // Filter out PDFs and keep only image files
    const imageExtensions = /\.(jpg|jpeg|png|gif)$/i;
    const filteredPhotos = photos.filter((photo) =>
      imageExtensions.test(photo.fileName)
    );

    // Group filtered photos in pairs of two
    for (let i = 0; i < filteredPhotos.length; i += 2) {
      groupedPhotos.push(filteredPhotos.slice(i, i + 2));
    }

    return (
      <table className="photo-table ">
        <tbody>
          {groupedPhotos.map((photoPair, index) => (
            <tr key={index}>
              {photoPair.map((photo) => (
                <td key={photo.id} className="verification w-50">
                  <div className="verificationPtos">
                    {renderImage(photo.fileName)}
                  </div>
                  <div className="verificationdetails">
                    <div className="detail-row">
                      <div className="label">Photo Name</div>
                      <div className="colon">:</div>
                      <div className="value">{photo.photoName}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Description</div>
                      <div className="colon">:</div>
                      <div className="value">{photo.description}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Comment</div>
                      <div className="colon">:</div>
                      <div className="value">{photo.comment}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Updated At</div>
                      <div className="colon">:</div>
                      <div className="value">
                        {new Date(photo.photoUpdatedAt).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </td>
              ))}

              {photoPair.length === 1 && <td></td>}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const VerifiedDocuments = ({ documents }) => {
    // Filter PDF files only (extension .pdf)
    const pdfExtensions = /\.(pdf)$/i;
    const filteredDocs = documents.filter((doc) =>
      pdfExtensions.test(doc.fileName)
    );

    // Group the filtered PDFs in pairs of 2
    const groupedDocs = [];
    for (let i = 0; i < filteredDocs.length; i += 2) {
      groupedDocs.push(filteredDocs.slice(i, i + 2));
    }

    return (
      <table className="document-table">
        <tbody>
          {groupedDocs.map((docPair, index) => (
            <tr key={index}>
              {docPair.map((doc) => (
                <td key={doc.id} className="verification w-50">
                  <div className="verificationdetails">
                    <div className="detail-row">
                      <div className="label">Document Name</div>
                      <div className="colon">:</div>
                      <div className="value">{doc.photoName}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Description</div>
                      <div className="colon">:</div>
                      <div className="value">{doc.description}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Comment</div>
                      <div className="colon">:</div>
                      <div className="value">{doc.comment}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Updated At</div>
                      <div className="colon">:</div>
                      <div className="value">
                        {new Date(doc.photoUpdatedAt).toLocaleString()}
                      </div>
                    </div>
                    {/* Link to open PDF in a new tab */}
                    <div className="detail-row">
                      <div className="label">Open Document</div>
                      <div className="colon">:</div>
                      <div className="value">
                        <a
                          href={`${BASE_URL}/images/${doc.fileName}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {doc.photoName}
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              ))}
              {docPair.length === 1 && <td></td>}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const CreditOfficerVerificationTable = ({ data }) => {
    const fields = [
      { key: "memberType", label: "Member Type" },
      { key: "fieldName", label: "Field Name" },
      { key: "fieldValue", label: "Field Value" },
      { key: "updatedAt", label: "Updated At" },
    ];

    return (
      <div>
        {data.length > 0 ? (
          data.map((item) => (
            <DetailTable
              key={item.id}
              details={{
                memberType: item.memberType,
                fieldName: item.fieldName,
                fieldValue: item.fieldValue,
                updatedAt: new Date(item.updatedAt).toLocaleString(),
              }}
              fields={fields}
            />
          ))
        ) : (
          <p className="paaratext"> Credit officer data not available.</p>
        )}
      </div>
    );
  };
  return (
    <Modal
      show={show}
      onHide={resetPhotoStates}
      dialogClassName="full-width-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center" onClick={handlePrintClick}>
          <button className="btn btn-success mx-2">View Document</button>
        </div>
      </Modal.Header>

      {memberDetails && (
        <>
          <div className="sub-header">
            <p>Sourcing Details</p>
          </div>

          {memberDetails ? (
            <DetailTable details={memberDetails} fields={sourcingFields} />
          ) : (
            <p className="paaratext">Sourcing details not available</p>
          )}

          <div className="sub-header">
            <p>Applicant's Personal Details</p>
          </div>

          {memberDetails?.memberDetails ? (
            <DetailTable
              details={memberDetails.memberDetails}
              fields={memberFields}
            />
          ) : (
            <p className="paaratext">Personal details not available</p>
          )}
          <div className="sub-header">
            <p>Member Documents </p>
          </div>
          {memberDetails?.memberDocuments ? (
            <PhotoTable
              details={memberDetails.memberDocuments}
              fields={[
                {
                  key: "memberPhoto",
                },
                {
                  key: "signaturePhoto",
                },
                {
                  key: "aadharFrontPhoto",
                },
                {
                  key: "aadharBackPhoto",
                },
                {
                  key: "anotherIdentityPhoto",
                },
                {
                  key: "bankPassbookPhoto",
                },
                {
                  key: "businessProofPhoto",
                },
                {
                  key: "rationCardPhoto",
                },
                {
                  key: "ownHouseProofPhoto",
                },
                {
                  key: "applicantLinkProofPhoto",
                },
                {
                  key: "other1",
                },
                {
                  key: "other2",
                },
                {
                  key: "other3",
                },
              ]}
            />
          ) : (
            <p className="paaratext">Member Documents not available</p>
          )}

          <div className="sub-header">
            <p>Employment Details</p>
          </div>
          {memberDetails?.businessDetails ? (
            <DetailTable
              details={memberDetails.businessDetails}
              fields={businessFields}
            />
          ) : (
            <p className="paaratext">Employment details not available</p>
          )}

          <div className="sub-header">
            <p>Applicant Residence Address</p>
          </div>
          {memberDetails?.memberDetails ? (
            <DetailTable
              details={memberDetails.memberDetails}
              fields={residenceFields}
            />
          ) : (
            <p className="paaratext">Applicant Residence not available</p>
          )}

          <div className="sub-header">
            <p>Applicant Permanent Address</p>
          </div>
          {memberDetails?.memberDetails ? (
            <DetailTable
              details={memberDetails.memberDetails}
              fields={permanentResidenceFields}
            />
          ) : (
            <p className="paaratext">
              Applicant Permanent address not available
            </p>
          )}

          <div className="sub-header">
            <p>Co-Applicant Personal Details</p>
          </div>
          {memberDetails?.coApplicantDetails ? (
            <DetailTable
              details={memberDetails.coApplicantDetails}
              fields={CoApplicantPersonalFields}
            />
          ) : (
            <p className="paaratext">
              Co Applicant Personal Details not available
            </p>
          )}

          <div className="sub-header">
            <p>Nominee Documents </p>
          </div>
          {memberDetails?.nomineeDocuments ? (
            <PhotoTable
              details={memberDetails.nomineeDocuments}
              fields={[
                {
                  key: "nomineePhoto",
                },
                {
                  key: "signaturePhoto",
                },
                {
                  key: "aadharFrontPhoto",
                },
                {
                  key: "aadharBackPhoto",
                },
                {
                  key: "anotherIdentityPhoto",
                },
                {
                  key: "bankPassbookPhoto",
                },
                {
                  key: "salaryProofPhoto",
                },
                {
                  key: "rationCardPhoto",
                },
                {
                  key: "ownHouseProofPhoto",
                },
                {
                  key: "applicantLinkProofPhoto",
                },
                {
                  key: "other1",
                },
                {
                  key: "other2",
                },
                {
                  key: "other3",
                },
              ]}
            />
          ) : (
            <p className="paaratext">Nominee Documents not available</p>
          )}
          <div className="sub-header">
            <p>Co-Applicant Employment Details</p>
          </div>
          {memberDetails?.familyBusinessDetails ? (
            <DetailTable
              details={memberDetails.familyBusinessDetails}
              fields={coApplicantBusinessFields}
            />
          ) : (
            <p className="paaratext">
              Co Applicant Employment Details not available
            </p>
          )}

          <div className="sub-header">
            <p>Co-Applicant Residence Address</p>
          </div>
          {memberDetails?.coApplicantDetails ? (
            <DetailTable
              details={memberDetails.coApplicantDetails}
              fields={coApplicantResidenceFields}
            />
          ) : (
            <p className="paaratext">
              Co Applicant Residence Details not available
            </p>
          )}

          <div className="sub-header">
            <p>Co-Applicant Permanent Address</p>
          </div>
          {memberDetails?.coApplicantDetails ? (
            <DetailTable
              details={memberDetails.coApplicantDetails}
              fields={coApplicantPermanentResidenceFields}
            />
          ) : (
            <p className="paaratext">
              Co Applicant Permanent Details not available
            </p>
          )}

          <div className="sub-header">
            <p>Existing Loan Details</p>
          </div>
          {memberDetails?.loanDetails ? (
            <DetailTable
              details={memberDetails.loanDetails}
              fields={loanDetailsFields}
            />
          ) : (
            <p className="paaratext">Existing loan details not available</p>
          )}

          <div className="sub-header">
            <p>Proposed Loan Details</p>
          </div>
          {memberDetails?.proposedLoanDetails ? (
            <DetailTable
              details={memberDetails.proposedLoanDetails}
              fields={proposedLoanDetailsFields}
            />
          ) : (
            <p className="paaratext">Proposed loan details not available</p>
          )}

          <div className="sub-header">
            <p>Bank Details</p>
          </div>
          {memberDetails?.bankDetails ? (
            <DetailTable
              details={memberDetails.bankDetails}
              fields={bankDetailsFields}
            />
          ) : (
            <p className="paaratext">Bank details not available</p>
          )}
        </>
      )}

      <div className="sub-header">
        <p>Field Manager Comment</p>
      </div>
      {memberDetails?.memberDetails ? (
        <DetailTable
          details={memberDetails.memberDetails}
          fields={fieldManagerMessage}
        />
      ) : (
        <p className="paaratext">Field Manager comment not available</p>
      )}

      <div className="sub-header">
        <p>Branch Manager Verification Photos</p>
      </div>
      {memberDetails?.branchManagerVerificationPhotosStaticDetails ? (
        <>
          <PhotoTable
            details={memberDetails.branchManagerVerificationPhotosStaticDetails}
            fields={[
              {
                key: "housePhoto1",
              },
              {
                key: "housePhoto2",
              },
              {
                key: "businessPhoto1",
              },
              {
                key: "businessPhoto2",
              },
              {
                key: "businessPhoto3",
              },
              {
                key: "businessPhoto4",
              },
              {
                key: "other1",
              },
              {
                key: "other2",
              },
            ]}
          />
          <table className="table">
            <tbody>
              <tr>
                <th>Description</th>
                <td>
                  {
                    memberDetails.branchManagerVerificationPhotosStaticDetails
                      .description
                  }
                </td>
              </tr>
              <tr>
                <th>Comment</th>
                <td>
                  {
                    memberDetails.branchManagerVerificationPhotosStaticDetails
                      .comment
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <p className="paaratext">
          Branch Manager Verification Photos not available
        </p>
      )}
      {/* Section for Branch Manager Uploaded Photos */}
      <div className="sub-header">
        <p>Branch Manager Uploaded Photos</p>
      </div>
      {memberDetails?.branchManagerVerificationPhotosDetails ? (
        <VerificationPhotos
          photos={memberDetails.branchManagerVerificationPhotosDetails}
        />
      ) : (
        <p className="paaratext">
          Branch Manager Uploaded Photos not available
        </p>
      )}

      {/* Section for Branch Manager Uploaded Documents (PDFs) */}
      <div className="sub-header">
        <p>Branch Manager Uploaded Documents</p>
      </div>

      {memberDetails?.branchManagerVerificationPhotosDetails ? (
        <VerifiedDocuments
          documents={memberDetails.branchManagerVerificationPhotosDetails}
        />
      ) : (
        <p className="paaratext">
          Branch Manager Uploaded Documents not available
        </p>
      )}

      <div className="sub-header">
        <p>Branch Manager Comment</p>
      </div>
      {memberDetails?.memberDetails ? (
        <DetailTable
          details={memberDetails.memberDetails}
          fields={branchManagerMessage}
        />
      ) : (
        <p className="paaratext">Branch Manager comment not available</p>
      )}

      <div className="sub-header">
        <p>Credit Officer Verification Photos</p>
      </div>
      {memberDetails?.creditOfficerVerificationPhotosStaticDetails ? (
        <>
          <PhotoTable
            details={memberDetails.creditOfficerVerificationPhotosStaticDetails}
            fields={[
              {
                key: "housePhoto1",
              },
              {
                key: "housePhoto2",
              },
              {
                key: "businessPhoto1",
              },
              {
                key: "businessPhoto2",
              },
              {
                key: "businessPhoto3",
              },
              {
                key: "businessPhoto4",
              },
              {
                key: "other1",
              },
              {
                key: "other2",
              },
            ]}
          />
          <table className="table">
            <tbody>
              <tr>
                <th>Description</th>
                <td>
                  {
                    memberDetails.creditOfficerVerificationPhotosStaticDetails
                      .description
                  }
                </td>
              </tr>
              <tr>
                <th>Comment</th>
                <td>
                  {
                    memberDetails.creditOfficerVerificationPhotosStaticDetails
                      .comment
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <p className="paaratext">
          Credit Officer Verification Photos not available
        </p>
      )}

      <div className="sub-header">
        <p>Credit Officer Uploaded Photos</p>
      </div>
      {memberDetails?.creditOfficerVerificationPhotosDetails ? (
        <>
          <VerificationPhotos
            photos={memberDetails.creditOfficerVerificationPhotosDetails}
          />
        </>
      ) : (
        <p className="paaratext">
          Credit Officer Verification Photos not available
        </p>
      )}

      <div className="sub-header">
        <p>Credit Officer Verification Data</p>
      </div>
      {memberDetails?.creditOfficerVerificationDataDetails ? (
        <CreditOfficerVerificationTable
          data={memberDetails.creditOfficerVerificationDataDetails}
        />
      ) : (
        <p className="paaratext">
          Credit Officer Verification Data not available
        </p>
      )}

      <div className="sub-header">
        <p>Credit Officer Generated Data</p>
      </div>
      {memberDetails?.memberDetails ? (
        <DetailTable
          details={memberDetails.memberDetails}
          fields={creditOfficerGeneratedData}
        />
      ) : (
        <p className="paaratext">Credit Officer Generated Data not available</p>
      )}

      <div className="sub-header">
        <p>Credit Officer Comment</p>
      </div>
      {memberDetails?.memberDetails ? (
        <DetailTable
          details={memberDetails.memberDetails}
          fields={creditOfficerMessage}
        />
      ) : (
        <p className="paaratext">Credit Officer comment not available</p>
      )}

      <div className="sub-header">
        <p> Comments</p>
      </div>
      {memberDetails?.memberDetails ? (
        <DetailTable details={memberDetails.memberDetails} fields={comments} />
      ) : (
        <p className="paaratext">comments not available</p>
      )}
      <Modal.Footer>
        <button className="btn btn-danger" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MemberDetailsModal;
