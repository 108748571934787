import React from "react";
import "../screens/MemberPrint.css";
import {
  DisbursementFormTable,
  disbursementFormFields,
} from "../components/TablePrintFormat";

const DisbursementRequestForm = (memberDetails) => {
  return (
    <div className="section sanction-letter-section">
      <div className=" d-flex justify-content-between">
        <div>
          <div className=" sanctionHeading">
            <p className="headingText">DISBURSEMENT REQUEST FORM</p>
            <p>(TO BE ISSUED BY THE BORROWER TO VETRI VIKAS CAPITAL TRUST )</p>
          </div>
          <div className="sanctionHeading" style={{ marginTop: "-50px" }}>
            <p className="headingText">பட்டுவாடா வேண்டுகோள் படிவம்</p>
            <p>
              (கடன் பெறுபவர், வெற்றிவிகாஸ் கேபிடல் டிரஸ்ட் - க்கு அளிக்க
              வேண்டும்)
            </p>
          </div>
        </div>
        <div>
          <img
            src={require("../assets/vetrivikaslogo.jpeg")}
            alt="logo"
            className="printlogo"
          />
        </div>
      </div>
      <div className="borderlineprint"></div>

      <div className="mt-5">
        <p>To, / பெறுநர்,</p>
        <p>Branch Manager / கிளை மேலாளர்</p>
        <p>VETRIVIKAS CAPITAL TRUST / வெற்றிவிகாஸ் கேபிடல் டிரஸ்ட் </p>
        <p>
          Location :<span style={{ textDecoration: "underline" }}>Madurai</span>
        </p>
        <p>
          இடம் : <span style={{ textDecoration: "underline" }}>Madurai</span>
        </p>
        <p>
          Sub : Disbursal Request for ‘ Loan Account Number / Application Ref.
          <span style={{ textDecoration: "underline" }}>
            {memberDetails.memberDetails.memberDetails.ApplicationId}
          </span>
        </p>
        <p>பொருள் : "பட்டுவாடா வேண்டுகோள்" கடன் கணக்கு எண் / விண்ணப்பம் எண்</p>
        <p>Dear Sir / Madam</p>
        <p>
          This is with reference to my / our loan applicant of
          <span style={{ textDecoration: "underline" }}>
            {memberDetails.memberDetails.memberDetails.ApplicationId}
          </span>
          ("Loan") from VETRIVIKAS CAPITAL TRUST
        </p>
        <p>
          இந்த கடிதம் வெற்றிவிகாஸ் கேபிடல் டிரஸ்ட் - ல் இருந்து
          <span style={{ textDecoration: "underline" }}>
            {memberDetails.memberDetails.memberDetails.ApplicationId}
          </span>{" "}
          கான எனது / எங்களின் ("கடன்") விண்ணப்பம்.
        </p>
        <p>
          I / We would like to request you to kindly disburse sanctioned loan in
          favour of the account details as provided below, I/We confirm that
          this transfer shall be deemed to be a disbursement made to Me / us in
          terms of the Loan Agreement.
        </p>
        <p>
          கீழே தரப்பட்டுள்ள அக்கவுண்ட் விவரங்களுக்கு ஆதரவாக அனுமதிக்கப்பட்ட கடனை
          தயவு செய்து பட்டுவாடா செய்யுமாறு நான் / நாங்கள் உங்களைக் கேட்டு
          கொள்கிறோம். கடன் ஒப்பந்தத்தின்படி எனக்கு / எங்களுக்கு செய்யப்பட்ட ஒரு
          பட்டுவாடாவாக இந்த பரிவர்த்தனை கருதப்படும் என்பதை நான் / நாங்கள்
          உறுதிப்படுத்துகிறோம்.
        </p>
        <p>Bankers Details :</p>
        <p>வங்கி விபரங்கள்</p>
      </div>
      <div>
        <DisbursementFormTable
          details={memberDetails.memberDetails}
          fields={disbursementFormFields}
        />
      </div>
      <div style={{ marginTop: "50px" }}>
        <p>Borrower Declaration</p>
        <p>கடன்பெறுபவர் அறிவிப்பு</p>
        <p>
          I/we hereby declare that the particulars and information given in the
          form are true and correct.{" "}
        </p>
        <p>
          படிவத்தில் கொடுக்கப்பட்ட விபரங்கள் மற்றும் தகவல்கள் உண்மையானவை மற்றும்
          சரியானவை என்று நான் / நாங்கள் அறிவிக்கிறோம்.
        </p>
        <p>நன்றி,</p>
      </div>
      <div>
        <div>
          <div className="d-flex justify-content-between mb-5">
            <p>
              Name of the Applicant :{" "}
              <span style={{ textDecoration: "underline" }}>
                {memberDetails.memberDetails.memberDetails.memberName}
              </span>{" "}
            </p>
            <p>Signature of the Applicant : ___________________________ </p>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <p>
              விண்ணப்பதாரர் பெயர் :{" "}
              <span style={{ textDecoration: "underline" }}>
                {memberDetails.memberDetails.memberDetails.ApplicationId}
              </span>{" "}
            </p>
            <p>விண்ணப்பதாரர் கையொப்பம் : ___________________________ </p>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between mb-5">
            <p>
              Name of the Co-Applicant :{" "}
              <span style={{ textDecoration: "underline" }}>
                {memberDetails.memberDetails.coApplicantDetails.coApplicantName}
              </span>
            </p>
            <p>Signature of the Co-Applicant : ___________________________ </p>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <p>
              இணை - விண்ணப்பதாரர் பெயர் :
              <span style={{ textDecoration: "underline" }}>
                {memberDetails.memberDetails.coApplicantDetails.coApplicantName}
              </span>
            </p>
            <p> இணை -விண்ணப்பதாரர் கையொப்பம் : ___________________________ </p>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between mb-5">
            <p>Name of Co-Applicant 2 : ___________________________ </p>
            <p>Signature of Co-Applicant 2 : ___________________________ </p>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <p>இணை - விண்ணப்பதாரர் பெயர் 2: ___________________________ </p>
            <p>இணை விண்ணப்பதாரர் கையொப்பம் 2 : ___________________________ </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisbursementRequestForm;
