// DataTable.js
import React from "react";
import { Table } from "react-bootstrap";

const DataTable = ({ columns, data }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{column.render(rowData, rowIndex)}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length}>No Data Available</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DataTable;
