import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useAdminAuth } from "./components/AdminAuthProvider";

const SuperAdminPrivateRoute = () => {
  const { adminUser } = useAdminAuth();
  const isSuperAdmin = adminUser && adminUser.userType === "superadmin";
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSuperAdmin) {
      navigate("/");
    }
  }, [isSuperAdmin, navigate]);

  return (
    isSuperAdmin && (
      <>
        <Outlet />
      </>
    )
  );
};

export default SuperAdminPrivateRoute;
